/* eslint-disable react/no-string-refs */
import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import SwiperCore, { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'
SwiperCore.use([Navigation])

import Layout from '../layouts/layout'
import Container from '../components/container'
import SEO from '../components/seo'
import Button from '../components/Button/Button'
import Card from '../components/Card/Card'
import Hero from '../components/Hero/Hero'
import { PrevIcon, NextIcon } from '../components/Pagination/PaginationIcons'

const ImpactText = styled.h2`
    font-size: 3em;
    font-weight: 900;
`

const Products = styled.div`
    position: relative;
    background-color: #fff;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #dddddd;
    text-align: center;
    padding: 1.5rem;
    margin: 0 auto 4rem;
    @media (min-width: 769px) {
        margin-top: -3rem;
    }
`

const Spacer = styled.div`
    display: 'block';
    height: ${props => props.height};
`

const ProductCards = styled.div`
    gap: 10px;
    @media (min-width: 769px) {
        display: flex;
    }
`

const ProductCard = styled(Card)`
    flex: 1;
    margin-bottom: 1rem;
`

const NewsContainer = styled.div`
    background-color: var(--color-light-blue);
`

const NewsCard = styled(Card)`
    height: 100%;
`

const NewsFooter = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    align-items: center;
`

const NewsNavigationButton = styled.button`
    border: none;
    appearance: none;
    cursor: pointer;
    background: none;
    height: 2.5rem;
    width: 2.5rem;
    &.swiper-button-disabled {
        opacity: 0.4;
    }
`

export default function IndexPage({ data }) {
    const posts = data.allWpPost.edges.map(({ node: post }) => post)

    return (
        <>
            <SEO
                title="Open Source RISC-V Cores and Tools"
                keywords={['risc-v', 'risc', 'bluespec']}
                description="Bluespec provides RISC-V processor IP and tools for developing RISC-V cores and subsystems. We take the risk out of RISC-V to enable you to achieve the highest levels of quality, performance and innovation."
            />
            <Layout>
                <Hero
                    image={data.heroImage}
                    overlay={false}
                    centered={false}
                    large
                >
                    <ImpactText>
                        RISC-V SIMPLIFIED
                    </ImpactText>
                    <Button to="/get-started">GET STARTED</Button>
                </Hero>
                <Container>
                    <Products>
                        <h2
                            css={{
                                color: '#7B818E;',
                                letterSpacing: 3,
                                fontSize: '1.2em',
                            }}
                        >
                            OUR PRODUCTS
                        </h2>
                        <ProductCards>
                            <ProductCard
                                title="HW-Assisted Verification"
                                image={data.product1Img.childImageSharp.fluid}
                                linkHref="/products#HW-Assisted"
                                linkTitle="Explore HW-Assisted Verification"
                            >
                                Fast, Accurate RISC-V Verification
                            </ProductCard>
                            <ProductCard
                                title="Portable Cores"
                                image={data.product2Img.childImageSharp.fluid}
                                linkHref="/products#portable"
                                linkTitle="Explore Portable Cores"
                            >
                                RISC-V Soft Cores
                            </ProductCard>
                            <ProductCard
                                title="Domain-Specific Cores"
                                image={data.product3Img.childImageSharp.fluid}
                                linkHref="/products#domain"
                                linkTitle="Explore Domain Specific Cores"
                            >
                                RISC-V for Acceleration
                            </ProductCard>
                        </ProductCards>
                    </Products>
                </Container>
                <Spacer height="10rem" />
                <NewsContainer>
                    <Container>
                        <h2
                            css={{
                                fontSize: '2rem',
                                letterSpacing: 1,
                                fontWeight: 700,
                                marginTop: '-10rem',
                            }}
                        >
                            NEWS ROOM
                        </h2>

                        <Swiper
                            spaceBetween={50}
                            slidesPerView={1}
                            breakpoints={{ 769: { slidesPerView: 2 } }}
                            navigation={{
                                prevEl: '.news-swiper-button-prev',
                                nextEl: '.news-swiper-button-next',
                            }}
                            css={{
                                marginBottom: '3rem',
                                paddingBottom: '1rem',
                            }}
                        >
                            {posts.map(post => (
                                <SwiperSlide key={post.id}>
                                    <NewsCard
                                        title={post.title}
                                        image={
                                            post.featuredImage &&
                                            post.featuredImage.node.localFile
                                                .childImageSharp.fluid
                                        }
                                        linkHref={post.link}
                                        shadow
                                        border={false}
                                    />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        <NewsFooter>
                            <Button to="/news" type="secondary">
                                VIEW ALL
                            </Button>
                            <div>
                                <NewsNavigationButton className="news-swiper-button-prev">
                                    <PrevIcon />
                                </NewsNavigationButton>
                                <NewsNavigationButton className="news-swiper-button-next">
                                    <NextIcon />
                                </NewsNavigationButton>
                            </div>
                        </NewsFooter>
                    </Container>
                </NewsContainer>
            </Layout>
        </>
    )
}

export const query = graphql`
    query HomeCoreItemsQuery {
        allWpPost(limit: 6, sort: { fields: date, order: DESC }) {
            edges {
                node {
                    ...PostListFields
                }
            }
        }
        heroImage: file(relativePath: { eq: "home/hero-bg-01@2x.png" }) {
            ...heroImage
        }
        product1Img: file(relativePath: { eq: "home/xilinx@2x.png" }) {
            childImageSharp {
                fluid(maxHeight: 200) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        product2Img: file(relativePath: { eq: "home/portable@2x.png" }) {
            childImageSharp {
                fluid(maxHeight: 200) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        product3Img: file(relativePath: { eq: "home/domain-feat-img@2x.png" }) {
            childImageSharp {
                fluid(maxHeight: 200) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
